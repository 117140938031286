/*@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&family=Space+Mono&family=Squada+One&display=swap");*/
/*@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Cy-SemiBold;
  src: url("./assets/fonts/Cy-SemiBold.woff2");
}
@font-face {
  font-family: Hiragino-Sans;
  src: url(./assets/fonts/HiraKakuProN-W4-AlphaNum-01.otf);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-CySemibold font-semibold text-White;
}

h1,
h2 {
  -webkit-text-stroke: 1px theme("colors.Pink");
  text-shadow: theme("colors.Pink") 0 0 17.94px;
  paint-order: stroke fill;
}

body {
  @apply bg-DarkBlue font-HiraginoSans font-medium m-0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  @apply text-White;
}

summary {
  cursor: pointer;
}

nav {
  min-height: 5rem;
}

.drop-shadow-pink:hover {
  text-shadow: theme("colors.Pink") 0 0 45.8px;
  paint-order: stroke fill;
}
@media screen and (max-width: 767px) {
  .schedule-block {
    /*max-width: 20rem;*/
    flex: 0 0 80%;
  }
}

@media screen and (max-width: 428px) {
  .hide-mobile {
    visibility: hidden;
  }
}
