#FAQ::-webkit-scrollbar {
    background: rgba(217, 217, 217, 0.5);
    border: 0.25em solid transparent;
    border-radius: 0.5em;
}

#FAQ::-webkit-scrollbar-thumb {
    background: rgb(218 98 72);
    border: 0.25em solid transparent;
    border-radius: 0.5em;
}

#FAQ summary::-webkit-details-marker {
    display: none;
}